<template>
  <div class="option-special">
    <div class="header"></div>
    <div class="player">
      <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"
        @play="onPlayerPlay($event)" @pause="onPlayerPause($event)">
      </video-player>
    </div>
    <div class="footer">
      <div class="text">
        <div>
          数据库介绍：大英百科250年来一直是全球教育出版行业的领航者，是世界上最权威的全科型参考资源。大英百科雇佣自己专职的内部专家团队，并拥有广泛的专家人脉网络：包括诺贝尔奖获得者，著名学者，专业顾问，内容专家和专题作家，以确保大英百科学术版的及时性，准确性，客观性，专业性和综合性。作为一个动态资源库，大英百科一直保持信息时时更新。
        </div>
        <div>
          数据库类型：外文
        </div>
        <div>
          访问地址： <a href="http://academic.eb.cnpeak.com" target="_blank">http://academic.eb.cnpeak.com</a> <img
            class="click" src="@/assets/img/click.png" alt="" srcset="">
        </div>
        <div>
          文献类型：百科全文、学术期刊和杂志全文(包括EBSCO)、韦氏大词典、视频音频等多媒体资料、推荐学术网站、图片、电子书和原始文献等。平均每月更新文章达到1,100篇以上。
        </div>
        <div>
          学科分类：自然科学和社会科学, 全科综合类资料库
        </div>

      </div>
      <div class="pdf" @click="download">
        <div class="content">
          <img src="@/assets/img/pdf.png" alt="">
          <div>
            <span>Britannica Academic <br>使用指南</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>

//   import { videoPlayer } from "vue-video-player";
export default {
  // components: {
  //   videoPlayer
  // },
  data() {
    return {
      inputValue: '',//搜索框的值
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",
          src: require('../assets/academic.mp4')  //你的视频地址（必填）
        }],
        poster: "", //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        // controlBar: {
        //   timeDivider: true,
        //   durationDisplay: true,
        //   remainingTimeDisplay: false,
        //   fullscreenToggle: true  //全屏按钮
        // }
      }

    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {

    download() {
      let url = `bri.pdf`
      const a = document.createElement('a')
      a.href = url
      a.download = 'Britannica Academic_使用指南.pdf' // 下载后文件名
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click() // 点击下载
      document.body.removeChild(a) // 下载完成移除元素
    }
  },
};
</script>
<style lang="less">
.option-special {
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #464646;
  position: relative;
  // margin-top: 102px;

  .txt {
    width: 61%;
    //height: 167px;
    font-family: MicrosoftYaHeiLight;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 48px;
    letter-spacing: 0px;
    color: #000000;
    margin: 0 auto;
  }

  .header {
    width: 70%;
    margin: 0 auto;
    height: 230px;
    background-size: 100%;
    background-image: url('../assets/img/sp-bg.png');

  }

  .click {
    position: absolute;
    width: 60px;
    height: 27px;
    margin-left: 6px;
  }

  .search {
    width: 70%;
    margin: 0 auto;
    position: absolute;
    top: 0;

    .el-input-group {
      width: 50%;
    }
  }

  .all {
    margin-top: 36px;
    width: 100%;
    height: 23px;
    text-align: center;
    font-family: MicrosoftYaHeiLight;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 48px;
    letter-spacing: 0px;
    color: #000000;
  }

  .dizhi {
    margin: 26px 0 105px 0;
    width: 100%;
    text-align: center;
    height: 26px;
    font-family: MicrosoftYaHeiLight;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 48px;
    letter-spacing: 0px;
    color: #2c5379;

    span {
      color: #000;
    }
  }

  .player {
    width: 70%;
    margin: 0px auto;
  }

  .video-js .vjs-big-play-button {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .footer {
    .text {
      width: 70%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 30px;
      text-align: left;
      background-color: rgb(87, 114, 144);
      color: aliceblue;
      font-size: 16px;

      >div {
        margin-top: 8px;

        a {
          color: aliceblue;
        }
      }

      >:nth-child(3) {
        position: relative;
      }
    }

    .pdf {
      position: fixed;
      right: 0;
      top: 200px;
      background-color: #F7F9FA;
      padding: 10px;
      border-radius: 18px 0 0 18px;
      box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);

      .content {
        display: flex;
        font-size: 12px;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
          height: auto;
        }

        >:nth-child(2) {
          display: flex;

          span {
            color: #000;
          }
        }
      }
    }
  }

}
</style>
  